<section
  class="zoning_analysis"
  [ngClass]="{
    'zoning_analysis--fixed':
      !adjustElement && twoContainer && !secondRowActive,
      'zoning_analysis--second_row': !!secondRowActive && addressSearchIsVisible,
    'zoning_analysis--fixed_bulk':
      !adjustElement && twoContainer && !secondRowActive && bulkSelected,
      'zoning_analysis--addressSearchNotVisible_secondrow':
      !addressSearchIsVisible && secondRowActive,
      'zoning_analysis--addressSearchNotVisible_firstrow':
      !addressSearchIsVisible && !secondRowActive,
      
  }"
>
  <div class="invisible_layer" *ngIf="this.setInvisibleLayer"></div>
  <section class="header_info">
    <div class="d-flex justify-content-between">
      <p class="header_text">Zoning Analysis</p>
      <span class="icon-left-arrow icon" (click)="close()"></span>
    </div>
  </section>

  <section class="no_data_cnt" *ngIf="!selectUseInfo">
    <h1 class="no_data_title">Select Lot</h1>
  </section>

  <section class="body" *ngIf="!!selectUseInfo">
    <ul class="tabs">
      <li
        class="tabs-options"
        [ngClass]="{
          'options--active': tab.active,
          'options--disabled': !tab.active && loadingGif === true
        }"
        (click)="handleTab(i)"
        *ngFor="let tab of infoTypeData; let i = index"
      >
        {{ tab.type }}
      </li>
    </ul>

    <!-- ================== Use/bulk options =================-->

    <section class="options" *ngIf="bulkSelected">
      <section
        class="option_cnt"
        ngClass="option_cnt--bulk"
        [ngClass]="{ 'option_cnt--bulk': bulkSelected }"
      >
        <!--select use-->
        <section class="zoning_options zoning_options--first_row">
          <div class="title_cnt">
            <h2 class="title">Select Use</h2>
            <article class="warning_cnt"></article>

            <ng-template #useBulkSelectedTooltip>
              <p
                style="margin-bottom: 0"
                *ngIf="!useIsSelected && !bulkIsSelected"
              >
                Select Use/Bulk
              </p>
              <p
                style="margin-bottom: 0"
                *ngIf="!useIsSelected && bulkIsSelected"
              >
                Select Use
              </p>
              <p
                style="margin-bottom: 0"
                *ngIf="useIsSelected && !bulkIsSelected"
              >
                Select Bulk
              </p>
            </ng-template>

            <ng-template #selectUseTooltip>
              <b> Information Context: </b> <br />
              The Zoning Resolution categorizes permissible uses into 4 general
              categories and <br />
              10 Use Groups:
              <ul style="padding: 0.5rem 1rem; margin-bottom: 0">
                <li><b> R</b>: Residential*</li>
                <li><b> CF</b>: Community Facility</li>
                <li><b> C</b>: Commercial</li>
                <li><b> M</b>: Manufacturing</li>
              </ul>

              <p>
                * The residential use selection excludes single- and two-family
                residences.
              </p>

              <p>
                All zoning districts allow some combination of these use groups.
                Special Districts <br />
                and supplemental provisions may further restrict specific uses
                and their location <br />
                within a building.
              </p>

              <b> Select Use: </b> <br />

              Choosing use categories impacts the allowable bulk types for your
              project. To <br />
              simplify this, we've introduced a user-friendly selection tool
              that organizes as-of-<br />right uses into four categories, each
              linked to the corresponding 'Select Bulk' <br />
              buttons.

              <br /><br />
              Click on the District button to view the permissible use groups
              for your property:
              <br />
              <div
                style="
                  width: 100%;
                  padding: 18px;
                  position: relative;
                  display: flex;
                  justify-content: center;
                  text-align: center;
                "
              >
                <img
                  src="../../../../assets/images/tooltipOvalImg.png"
                  alt="imgOval"
                  style="width: 50px; position: relative; left: 0"
                />
                <img
                  src="../../../../assets/images/pointer.png"
                  style="
                    width: 26px;
                    position: absolute;
                    font-size: 2rem;
                    right: 42%;
                    bottom: 6px;
                  "
                />
              </div>
            </ng-template>
            <article class="info_cnt">
              <i
                class="icon-info icon"
                tooltipClass="za_select_use_data"
                [ngbTooltip]="selectUseTooltip"
                placement="right"
                container="body"
              ></i>
            </article>
          </div>

          <ul
            class="elements elements--block"
            [ngClass]="{
              'elements--margin_space': item && !item.useBarList[i]?.visible
            }"
            id="firstList"
            *ngFor="let item of useSelectionList; let i = index"
          >
            <section class="elements_container">
              <div
                style="cursor: pointer"
                (click)="openUseTable(item.selectInfo)"
                class="oval_info oval_info--use"
                [ngClass]="{
                  'use--single': hasEmptyType(i) ,
                  'oval_info--large': item && item.selectInfo.length > 1,
                }"
              >
                <p
                  class="info_text"
                  [ngClass]="{
                    'info_text--large': item && item.selectInfo.length > 1
                  }"
                  *ngFor="let oval of item.selectInfo"
                  [innerHTML]="addStringAfterFirstSlash(oval.type)"
                ></p>
              </div>
              <li
                class="elements-item"
                *ngFor="let ele of item.selectOptions; let z = index"
                [ngClass]="{
                  'item--selected': ele.active,
                  'item--disabled_select': !ele.enabled
                }"
                (click)="selectItemRow(z, i)"
              >
                <p
                  class="item_text"
                  [ngClass]="{ 'item_text--active': ele.active }"
                >
                  {{ ele.text }}
                </p>
              </li>
            </section>

            <section *ngFor="let barCnt of item.useBarList; let bIndex = index">
              <article class="bar_cnt" *ngIf="barCnt.visible">
                <p
                  class="bar_text"
                  [ngClass]="{
                    'bar_text--enabled': !barCnt?.disabled
                  }"
                >
                  {{ barCnt?.rText }}
                </p>
                <ul
                  class="bar"
                  [ngClass]="{
                    'bar--enabled_large':
                      !barCnt?.disabled && barCnt.values.length > 2,
                    'bar--enabled': !barCnt?.disabled
                  }"
                >
                  <li
                    class="bar-item"
                    [ngClass]="{
                      'item--active': !barCnt?.disabled && ele.selected,
                      'item--unactive': !barCnt?.disabled && !ele.selected,
                      'item--large': ele?.visible && ele?.values.length > 2
                    }"
                    *ngFor="let ele of barCnt.values; let y = index"
                    (click)="selectBarItem(y, i, bIndex)"
                  >
                    {{ ele.value }}
                  </li>
                </ul>
              </article>
            </section>
          </ul>
        </section>

        <ng-template #selectBulkTooltip>
          <b> Information Context: </b> <br />
          The size and shape of a building are governed by regulations known as
          bulk <br />regulations. To simplify comprehension of the various
          options available for a <br />
          project, we have standardized them into distinct envelope types:
          <ul style="padding: 0.5rem 1rem; margin-bottom: 0">
            <li><b> QH</b>: Quality Housing</li>
            <li><b> BH</b>: Basic Height</li>
            <li><b> AH</b>: Alternate Height</li>
            <li><b> QH + QGF</b>: Quality Housing + Qualifying Ground Floor</li>
            <li><b> ST BH</b>: Standard Tower (Basic Height)</li>
            <li><b> ST AH</b>: Standard Tower (Alternate Height)</li>
            <li><b> TB</b>: Tower-on-a-Base</li>
            <li><b> SD</b>: Special Districts</li>
          </ul>

          <b> Select Bulk: </b> <br />

          After selecting the intended uses, choose the bulk type that best
          suits your <br />
          project. Once a use/bulk combination has been selected, click the
          "Assemble" <br />
          button to view the maximum allowable envelope.

          <br /><br />
          Bulk selection can be made either by clicking on the District or the
          Bulk button:
          <br />
          <div
            style="
              width: 100%;
              padding: 18px;
              position: relative;
              display: flex;
              justify-content: center;
              text-align: center;
            "
          >
            <img
              src="../../../../assets/images/tooltipImgBulk.gif"
              alt="imgBulk"
              style="width: 100px; position: relative; left: -14px"
            />
          </div>
        </ng-template>

        <section class="zoning_options">
          <div class="title_cnt">
            <h2 class="title">Select Bulk</h2>
            <article class="info_cnt">
              <i
                class="icon-info icon"
                tooltipClass="za_select_bulk_tooltip_data"
                [ngbTooltip]="selectBulkTooltip"
                placement="right"
                container="body"
              ></i>
            </article>
          </div>

          <ul class="elements elements--bulks" *ngIf="bulkList.length > 0">
            <li
              [id]="'div' + y"
              class="elements-item item--img"
              (click)="selectBulkImg(y)"
              *ngFor="let opt of bulkList[0].eles; let y = index"
              [ngClass]="{
                'item--disabled': opt.type === 'disabled',
                'item--selected': opt.active
              }"
              [ngStyle]="{
                'background-image': 'url(' + opt.image + ')',
                'background-color':
                  opt.type === 'selectable' ? '#474242' : 'transparent'
              }"
            >
              <div
                class="item_text item_text--img"
                [ngClass]="{
                  'img--selectable': opt.type === 'selectable',
                  'img--active': opt.active
                }"
              ></div>
              <P
                class="bulk_name"
                [ngClass]="{ 'bulk_name--active': opt.active }"
              >
                {{ validateText(opt.text) }}
              </P>
              <article class="oval_cnt">
                <div
                  *ngFor="let item of opt.ovalInfo; let o = index"
                  class="oval_info"
                  (click)="activateBulkOval($event, o, y)"
                  [ngClass]="{
                      'oval_info--active': item.active === true,
                      'oval_info--disabled': !item.enabled,
                    }"
                  [ngStyle]="{
                    display:
                      (item.value && !item.visible) ||
                      (item.secondValue && !item.visible)
                        ? 'none'
                        : 'flex'
                  }"
                >
                  <p class="info_text" *ngIf="item.value.length <= 4">
                    {{ item.value }} <span *ngIf="!!item.secondValue"></span>
                    <br />
                    {{ item.secondValue }}
                  </p>

                  <p
                    class="info_text"
                    *ngIf="item.value.length > 4"
                    [innerHTML]="addStringAfterFirstSlash(item.value)"
                  >
                    {{ item.value }} <span *ngIf="!!item.secondValue"></span>
                    {{ item.secondValue }}
                  </p>
                </div>
              </article>
            </li>
          </ul>
        </section>

        <ng-template #selectYardTooltip>
          <b> Information Context: </b> <br />
          Yards are required open spaces along property lines that limit
          building placement on a lot. Yard <br />
          depth and location vary based on the selected use/bulk and the type of
          lot:
          <ul style="padding: 0.5rem 1rem; margin-bottom: 0">
            <li>
              <b> Corner Lot</b>: rear yard is required within 100 feet of a
              corner or the short dimension of a <br />
              block measuring 230' or less
            </li>
            <li>
              <b> Interior lot</b>: Only interior lots require a rear yard since
              they have a rear yard line.
            </li>
            <li>
              <b> Through lot</b>: Require a rear yard equivalent (RYE), equal
              to the depth of two rear yards.
            </li>
          </ul>
        </ng-template>

        <ng-template #selectYardSubTooltip>
          <b> RYE for through lot portion: </b> <br />
          Users can choose different Rear Yard Equivalent (RYE) options to view
          the regulatory impacts for through lot portion when applicable:
          <ul style="padding: 0.5rem 1rem; margin-bottom: 0">
            <li><b> A</b>: RYE at Centerline of the lot</li>
            <li><b> B</b>: RYE at Street line</li>
            <li><b> C</b>: RYE at Side lot line*</li>
          </ul>
          * Note that option C (Side lot lines) is not available for irregular
          through lots, but it may be permitted.
        </ng-template>

        <!--Select rear-->
        <section class="zoning_options">
          <div class="title_cnt">
            <h2 class="title">Select Yard</h2>
            <article class="info_cnt">
              <i
                class="icon-info icon"
                tooltipClass="za_select_yards_tooltip_data"
                [ngbTooltip]="selectYardTooltip"
                placement="right"
                container="body"
              ></i>
            </article>
          </div>
          <div class="title_cnt title_cnt--sub_no_border">
            <p class="subtitle">RYE for through lot portion:</p>
            <article class="info_cnt">
              <i
                class="icon-info icon"
                tooltipClass="za_select_subYard_tooltip_data"
                [ngbTooltip]="selectYardSubTooltip"
                placement="right"
                container="body"
              ></i>
            </article>
          </div>
          <ul class="elements elements--bulks" *ngIf="rearYardList.length > 0">
            <li
              class="elements-item item--img item--yard_b"
              *ngFor="let rear of rearYardList[0].eles; let z = index"
              [ngClass]="{
                'item--disabled': rear.type === 'disabled',
                'item--selected': rear.active
              }"
              [ngStyle]="{ 'background-image': 'url(' + rear.image + ')' }"
              (click)="selectYardRow(z, 0)"
            >
              <div
                class="item_text item_text--img"
                [ngClass]="{
                  'img--selectable': rear.type === 'selectable',
                  'img--active': rear.active
                }"
              >
                <ul
                  class="rear_btn_opt"
                  [ngClass]="{
                    'rear_btn_opt--b': btn.btnName.includes('B'),
                    'rear_btn_opt_b--selected':
                      btn.btnName.includes('B') && !!btn.selected,
                    'rear_btn_opt--selected': !!btn.selected,
                    'rear_btn_opt--deselected':
                      !btn.selected && rear.buildingOpt.length === 1,
                    'rear_btn_opt--disabled': rear.type === 'disabled'
                  }"
                  *ngFor="let btn of rear.buildingOpt; let r = index"
                  (click)="selectYardRow(z, r)"
                >
                  <li
                    class="rear_btn"
                    [ngClass]="{
                      'rear_btn--single':
                        btn.btnName.includes('A') || btn.btnName.includes('C'),
                      'rear_btn--active': !!btn.selected
                    }"
                  >
                    {{ btn.btnName }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>

        <ng-template #selectVisualizeYardTooltip>
          <b> Visualize Yard Checkbox: </b> <br />
          Toggle this to turn yards on or off.
        </ng-template>
        <!--Qualifying Ground Floor-->
        <section class="zoning_options mt-3">
          <div class="title_cnt title_cnt--no_border">
            <span class="checkbox">
              <div class="d-flex justify-content-between">
                <app-checkbox
                  [id]="'checkbox-qualifyingGround'"
                  [checked]="!!visualizedYards"
                  (onclick)="handleVisualizedYards($event)"
                  (disabled)="
                    (!this.yardsConfigData.A ||
                      this.yardsConfigData.B_1 ||
                      !this.yardsConfigData.B_2 ||
                      !this.yardsConfigData.B_3 ||
                      !this.yardsConfigData.C)
                  "
                ></app-checkbox>
              </div>
            </span>
            <h2 class="title title--check">Visualize Yards</h2>
            <article class="info_cnt">
              <i
                class="icon-info icon"
                tooltipClass="za_select_visualize_tooltip_data"
                [ngbTooltip]="selectVisualizeYardTooltip"
                placement="right"
                container="body"
              ></i>
            </article>
          </div>
        </section>
      </section>
      <article class="btn_cnt">
        <button
          class="btn_assemble"
          [ngClass]="{
          'btn_assemble--disabled': loadingGif === true || (!enableAssembleBtn && !assembleIsActivated),
        }"
          (click)="setEnvelope()"
          [ngbTooltip]="
            !useIsSelected || !bulkIsSelected ? useBulkSelectedTooltip : ''
          "
          placement="top"
          container="body"
          [disabled]="
            loadingGif === true ||
            !disabledTemporalAssaemble ||
            (!enableAssembleBtn && !assembleIsActivated) ||
            (!useIsSelected && !bulkIsSelected)
          "
        >
          Assemble
        </button>
        <button
          class="btn_tags"
          [ngClass]="{
          'btn_tags--disabled': loadingGif === true,
        }"
          (click)="setTag(tagValue)"
          [disabled]="
            loadingGif === true || (!tagBtnIsEnabled && !firstEnvelope)
          "
        >
          Tags
        </button>
        <button
          class="btn_hide btn_hide--no_margin"
          (click)="removeEnvelope()"
          [ngClass]="{ 'btn_hide--disabled': loadingGif === true }"
          [disabled]="loadingGif === true || !firstEnvelope"
        >
          Clear
        </button>
      </article>
    </section>

    <!-- ================== Regulations options =================-->

    <section class="options" *ngIf="regulationSelected">
      <article
        class="option_cnt"
        [ngClass]="{ 'option_cnt--hidden_scroll': !compareIsBblIsAssembled }"
      >
        <section
          class="regulations_cnt"
          *ngIf="
            regulationSelected &&
            regulationsOptions.length > 0 &&
            compareIsBblIsAssembled
          "
        >
          <article class="btn_cnt">
            <button class="btn_close_all" (click)="handleAccordion(!allOpen)">
              {{ allOpen ? "Close All" : "Open All" }}
            </button>
          </article>
          <ul class="custom_accordion">
            <div
              class="custom_accordion-item"
              *ngFor="let item of regulationsOptions; let i = index"
            >
              <div class="custom_accordion-header" (click)="toggleAccordion(i)">
                <p class="accordion_title">{{ item.optionTitle }}</p>
                <span
                  class="icon-menu-arrow icon_header"
                  [ngClass]="{
                    'icon_header--selected': accordionActiveIndex[i]
                  }"
                ></span>
              </div>
              <div
                class="custom_accordion-body"
                [class.open]="accordionActiveIndex[i]"
              >
                <div class="values_cnt">
                  <p
                    class="allowed_txt"
                    [ngClass]="{ 'allowed_txt--relative': !!item.noOvalData }"
                  >
                    Allowed / Required
                  </p>

                  <section *ngIf="item.cap === 1">
                    <section
                      class="info_data_cnt"
                      *ngFor="
                        let keyData of assembleData.message.REGULATIONS.CAP_01
                          .CIRCLES
                      "
                    >
                      <div
                        class="oval_container"
                        *ngIf="
                          (keyData.ZONEDIST &&
                            keyData.ZONEDIST !== 'ADJUSTED') ||
                          keyData.OVERLAY
                        "
                      >
                        <div
                          style="cursor: pointer"
                          class="oval_info oval_info--use"
                          [ngClass]="{
                  'use--single': !!keyData.ZONEDIST && !keyData.OVERLAY  ,
                  'oval_info--large': !!keyData.OVERLAY ,
                }"
                        >
                          <p class="info_text">
                            {{ keyData.ZONEDIST }}
                          </p>
                          <p class="info_text">
                            {{ keyData.OVERLAY }}
                          </p>
                        </div>

                        <p class="bulk_text">
                          {{ keyData.BULK }}
                        </p>
                      </div>

                      <p
                        class="adjusted_text"
                        *ngIf="
                          keyData.ZONEDIST && keyData.ZONEDIST === 'ADJUSTED'
                        "
                      >
                        {{ keyData.ZONEDIST }}
                      </p>

                      <article class="info_data_cnt" *ngIf="keyData?.R">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.R.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.VALUE[0]
                                )
                              }"
                              [innerHTML]="keyData?.R.VALUE[0]"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.CF">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.CF.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.VALUE[0]
                                )
                              }"
                              [innerHTML]="keyData?.CF.VALUE[0]"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.C">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.C.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.VALUE[0]
                                )
                              }"
                              [innerHTML]="keyData?.C.VALUE[0]"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.M">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.M.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.VALUE[0]
                                )
                              }"
                              [innerHTML]="keyData?.M.VALUE[0]"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_container" *ngIf="!!keyData?.KEY">
                        <div class="info_data">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(keyData.KEY)
                            }"
                            [innerHTML]="getFormatedTxt(keyData.KEY)"
                          ></p>
                        </div>

                        <div class="info_values">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(
                                keyData.VALUE[0]
                              )
                            }"
                            [innerHTML]="keyData.VALUE[0]"
                          ></p>
                        </div>
                      </article>
                    </section>
                  </section>

                  <section *ngIf="item.cap === 2">
                    <section
                      class="info_data_cnt"
                      *ngFor="
                        let keyData of assembleData.message.REGULATIONS.CAP_02
                          .CIRCLES
                      "
                    >
                      <div
                        class="oval_container"
                        *ngIf="
                          (keyData.ZONEDIST &&
                            keyData.ZONEDIST !== 'ADJUSTED') ||
                          keyData.OVERLAY
                        "
                      >
                        <div
                          style="cursor: pointer"
                          class="oval_info oval_info--use"
                          [ngClass]="{
                  'use--single': !!keyData.ZONEDIST && !keyData.OVERLAY  ,
                  'oval_info--large': !!keyData.OVERLAY ,
                }"
                        >
                          <p class="info_text">
                            {{ keyData.ZONEDIST }}
                          </p>
                          <p class="info_text">
                            {{ keyData.OVERLAY }}
                          </p>
                        </div>

                        <p class="bulk_text">
                          {{ keyData.BULK }}
                        </p>
                      </div>

                      <p
                        class="adjusted_text"
                        *ngIf="
                          keyData.ZONEDIST && keyData.ZONEDIST === 'ADJUSTED'
                        "
                      >
                        {{ keyData.ZONEDIST }}
                      </p>

                      <article class="info_data_cnt" *ngIf="keyData?.R">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.R.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.R.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.CF">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.CF.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.CF.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.C">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.C.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.C.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.M">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.M.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.M.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_container" *ngIf="!!keyData?.KEY">
                        <div class="info_data">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(keyData.KEY)
                            }"
                            [innerHTML]="getFormatedTxt(keyData.KEY)"
                          ></p>
                        </div>

                        <div class="info_values">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(
                                keyData.VALUE[0]
                              )
                            }"
                            *ngFor="let val of keyData?.VALUE"
                            [innerHTML]="val"
                          ></p>
                        </div>
                      </article>
                    </section>
                  </section>

                  <section *ngIf="item.cap === 3">
                    <section
                      class="info_data_cnt"
                      *ngFor="
                        let keyData of assembleData.message.REGULATIONS.CAP_03
                          .CIRCLES
                      "
                    >
                      <div
                        class="oval_container"
                        *ngIf="
                          keyData.ZONEDIST && keyData.ZONEDIST !== 'ADJUSTED'
                        "
                      >
                        <div
                          style="cursor: pointer"
                          class="oval_info oval_info--use"
                          [ngClass]="{
                  'use--single': !!keyData.ZONEDIST && !keyData.OVERLAY  ,
                  'oval_info--large': !!keyData.OVERLAY ,
                }"
                        >
                          <p class="info_text">
                            {{ keyData.ZONEDIST }}
                          </p>
                          <p class="info_text">
                            {{ keyData.OVERLAY }}
                          </p>
                        </div>

                        <p class="bulk_text">
                          {{ keyData.BULK }}
                        </p>
                      </div>

                      <p
                        class="adjusted_text"
                        *ngIf="
                          keyData.ZONEDIST && keyData.ZONEDIST === 'ADJUSTED'
                        "
                      >
                        {{ keyData.ZONEDIST }}
                      </p>

                      <article class="info_data_cnt" *ngIf="keyData?.R">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.R.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.R.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.CF">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.CF.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.CF.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.C">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.C.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.C.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.M">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.M.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.M.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_container" *ngIf="!!keyData?.KEY">
                        <div class="info_data">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(keyData.KEY)
                            }"
                            [innerHTML]="getFormatedTxt(keyData.KEY)"
                          ></p>
                        </div>

                        <div class="info_values">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(
                                keyData.VALUE[0]
                              )
                            }"
                            *ngFor="let val of keyData?.VALUE"
                            [innerHTML]="val"
                          ></p>
                        </div>
                      </article>
                    </section>
                  </section>

                  <section *ngIf="item.cap === 4">
                    <section
                      class="info_data_cnt"
                      *ngFor="
                        let keyData of assembleData.message.REGULATIONS.CAP_04
                          .CIRCLES
                      "
                    >
                      <div
                        class="oval_container"
                        *ngIf="
                          (keyData.ZONEDIST &&
                            keyData.ZONEDIST !== 'ADJUSTED') ||
                          keyData.OVERLAY
                        "
                      >
                        <div
                          style="cursor: pointer"
                          class="oval_info oval_info--use"
                          [ngClass]="{
                  'use--single': !!keyData.ZONEDIST && !keyData.OVERLAY  ,
                  'oval_info--large': !!keyData.OVERLAY ,
                }"
                        >
                          <p class="info_text">
                            {{ keyData.ZONEDIST }}
                          </p>
                          <p class="info_text">
                            {{ keyData.OVERLAY }}
                          </p>
                        </div>

                        <p class="bulk_text">
                          {{ keyData.BULK }}
                        </p>
                      </div>

                      <p
                        class="adjusted_text"
                        *ngIf="
                          keyData.ZONEDIST && keyData.ZONEDIST === 'ADJUSTED'
                        "
                      >
                        {{ keyData.ZONEDIST }}
                      </p>

                      <article class="info_data_cnt" *ngIf="keyData?.R">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.R.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.R.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.CF">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.CF.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.CF.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.C">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.C.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.C.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.M">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.M.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.M.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_container" *ngIf="!!keyData?.KEY">
                        <div class="info_data">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(keyData.KEY)
                            }"
                            [innerHTML]="getFormatedTxt(keyData.KEY)"
                          ></p>
                        </div>

                        <div class="info_values">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(
                                keyData.VALUE[0]
                              )
                            }"
                            *ngFor="let val of keyData?.VALUE"
                            [innerHTML]="val"
                          ></p>
                        </div>
                      </article>
                    </section>
                  </section>

                  <section *ngIf="item.cap === 5">
                    <section
                      class="info_data_cnt"
                      *ngFor="
                        let keyData of assembleData.message.REGULATIONS.CAP_05
                          .CIRCLES
                      "
                    >
                      <div
                        class="oval_container"
                        *ngIf="
                          (keyData.ZONEDIST &&
                            keyData.ZONEDIST !== 'ADJUSTED') ||
                          keyData.OVERLAY
                        "
                      >
                        <div
                          style="cursor: pointer"
                          class="oval_info oval_info--use"
                          [ngClass]="{
                  'use--single': !!keyData.ZONEDIST && !keyData.OVERLAY  ,
                  'oval_info--large': !!keyData.OVERLAY ,
                }"
                        >
                          <p class="info_text">
                            {{ keyData.ZONEDIST }}
                          </p>
                          <p class="info_text">
                            {{ keyData.OVERLAY }}
                          </p>
                        </div>

                        <p class="bulk_text">
                          {{ keyData.BULK }}
                        </p>
                      </div>

                      <p
                        class="adjusted_text"
                        *ngIf="
                          keyData.ZONEDIST && keyData.ZONEDIST === 'ADJUSTED'
                        "
                      >
                        {{ keyData.ZONEDIST }}
                      </p>

                      <article class="info_data_cnt" *ngIf="keyData?.R">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.R.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.R.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.CF">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.CF.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.CF.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.C">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.C.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.C.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.M">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.M.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.M.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_container" *ngIf="!!keyData?.KEY">
                        <div class="info_data">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(keyData.KEY)
                            }"
                            [innerHTML]="getFormatedTxt(keyData.KEY)"
                          ></p>
                        </div>

                        <div class="info_values">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(
                                keyData.VALUE[0]
                              )
                            }"
                            *ngFor="let val of keyData?.VALUE"
                            [innerHTML]="val"
                          ></p>
                        </div>
                      </article>
                    </section>
                  </section>

                  <section *ngIf="item.cap === 6">
                    <section
                      class="info_data_cnt"
                      *ngFor="
                        let keyData of assembleData.message.REGULATIONS.CAP_06
                          .CIRCLES
                      "
                    >
                      <div
                        class="oval_container"
                        *ngIf="
                          (keyData.ZONEDIST &&
                            keyData.ZONEDIST !== 'ADJUSTED') ||
                          keyData.OVERLAY
                        "
                      >
                        <div
                          style="cursor: pointer"
                          class="oval_info oval_info--use"
                          [ngClass]="{
                  'use--single': !!keyData.ZONEDIST && !keyData.OVERLAY  ,
                  'oval_info--large': !!keyData.OVERLAY ,
                }"
                        >
                          <p class="info_text">
                            {{ keyData.ZONEDIST }}
                          </p>
                          <p class="info_text">
                            {{ keyData.OVERLAY }}
                          </p>
                        </div>

                        <p class="bulk_text">
                          {{ keyData.BULK }}
                        </p>
                      </div>
                      <p
                        class="adjusted_text"
                        *ngIf="
                          keyData.ZONEDIST && keyData.ZONEDIST === 'ADJUSTED'
                        "
                      >
                        {{ keyData.ZONEDIST }}
                      </p>

                      <article class="info_data_cnt" *ngIf="keyData?.R">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.R.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.R.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.R.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.CF">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.CF.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.CF.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.CF.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.C">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.C.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.C.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.C.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_data_cnt" *ngIf="keyData?.M">
                        <article class="info_container">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(keyData?.M.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  keyData?.M.VALUE[0]
                                )
                              }"
                              *ngFor="let val of keyData?.M.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </article>

                      <article class="info_container" *ngIf="!!keyData?.KEY">
                        <div class="info_data">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(keyData.KEY)
                            }"
                            [innerHTML]="getFormatedTxt(keyData.KEY)"
                          ></p>
                        </div>

                        <div class="info_values">
                          <p
                            class="info_data-text"
                            [ngClass]="{
                              'text--no_white_space': hasNoNewLine(
                                keyData.VALUE[0]
                              )
                            }"
                            *ngFor="let val of keyData?.VALUE"
                            [innerHTML]="val"
                          ></p>
                        </div>
                      </article>
                    </section>
                  </section>

                  <section *ngIf="item.cap === 7">
                    <section
                      class="info_data_cnt"
                      *ngFor="let keyData of capSevenArray.CIRCLES"
                    >
                      <div
                        class="oval_container"
                        *ngIf="
                          (keyData.ZONEDIST &&
                            keyData.ZONEDIST !== 'ADJUSTED') ||
                          keyData.OVERLAY
                        "
                      >
                        <div
                          style="cursor: pointer"
                          class="oval_info oval_info--use"
                          [ngClass]="{
                  'use--single': !!keyData.ZONEDIST && !keyData.OVERLAY  ,
                  'oval_info--large': !!keyData.OVERLAY ,
                }"
                        >
                          <p class="info_text">
                            {{ keyData.ZONEDIST }}
                          </p>
                          <p class="info_text">
                            {{ keyData.OVERLAY }}
                          </p>
                        </div>

                        <p class="bulk_text">
                          {{ keyData.BULK }}
                        </p>
                      </div>

                      <p
                        class="adjusted_text"
                        *ngIf="
                          keyData.ZONEDIST && keyData.ZONEDIST === 'ADJUSTED'
                        "
                      >
                        {{ keyData.ZONEDIST }}
                      </p>

                      <section *ngFor="let capSeven of keyData.SECTION">
                        <article class="info_container" *ngIf="!!capSeven?.KEY">
                          <div class="info_data">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  capSeven.KEY
                                )
                              }"
                              [innerHTML]="getFormatedTxt(capSeven.KEY)"
                            ></p>
                          </div>

                          <div class="info_values">
                            <p
                              class="info_data-text"
                              [ngClass]="{
                                'text--no_white_space': hasNoNewLine(
                                  capSeven.VALUE[0]
                                )
                              }"
                              *ngFor="let val of capSeven?.VALUE"
                              [innerHTML]="val"
                            ></p>
                          </div>
                        </article>
                      </section>
                    </section>
                  </section>
                </div>
              </div>
            </div>
          </ul>
        </section>
        <section class="options" *ngIf="!compareIsBblIsAssembled">
          <p class="no_envelope_message">
            Assemble envelope to view regulations
          </p>
        </section>
      </article>
    </section>

    <!-- ================== Layers options =================-->

    <section class="options" *ngIf="layerSelected">
      <article class="option_cnt option_cnt--no_scroll">
        <section class="layers_cnt" id="layers">
          <section
            class="principal_check"
            *ngFor="let item of layersList; let i = index"
          >
            <section>
              <div class="input_cnt">
                <app-switch
                  class="switch_item"
                  [params]="item.switch"
                  (onclick)="switchToggle(i, $event.target.checked)"
                ></app-switch>
                <div class="col-md-9">
                  <p
                    class="item_text"
                    [ngClass]="{ 'item_text--enabled': item.switch.enabled }"
                  >
                    {{ item.label }}
                  </p>
                </div>
              </div>
              <section
                class="check_cnt"
                [ngStyle]="{
                  '--percentage': !!item.switch.enabled ? item.percentage : 0
                }"
                [ngClass]="{
                  'check_cnt--visible':
                    !!item.switch?.enabled && !!item.optionsList
                }"
              >
                <ul class="secondary_check">
                  <li
                    class="secondary_check-item"
                    *ngFor="let opt of item.optionsList; let x = index"
                  >
                    <span class="checkbox">
                      <div class="d-flex justify-content-between">
                        <app-checkbox
                          [id]="'checkbox-' + opt.optionName + '-' + x"
                          [disabled]="!item.switch?.enabled"
                          [checked]="!!opt.enabled"
                          (onclick)="
                            handleOptionsCheckbox(null, $event, x, false)
                          "
                        ></app-checkbox>
                        <label
                          class="checkbox-label"
                          [ngClass]="{
                            'label--disabled': !item.switch?.enabled
                          }"
                          >{{ opt.label }}</label
                        >
                      </div>
                    </span>
                  </li>
                </ul>
              </section>
            </section>
          </section>
        </section>
      </article>
    </section>
  </section>
</section>
